import React, { Component } from 'react'
import axios from 'axios'
import { encode } from 'src/utils'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import PageHelmet from 'src/components/PageHelmet'
import Thanks from 'src/components/Thanks'
import BecomeHeroPage from 'src/components/BecomeHeroPage'
import withLayout from 'src/components/withLayout'
import { injectIntl } from 'react-intl'

class BecomeHero extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSended: false,
      form: {
        name: '',
        projectName: '',
        projectDescription: '',
        contacts: '',
      },
    }
  }

  handleNameChange = (name) => {
    this.setState((state) => ({
      ...state,
      form: {
        ...state.form,
        name,
      },
    }))
  }

  handleProjectNameChange = (projectName) => {
    this.setState((state) => ({
      ...state,
      form: {
        ...state.form,
        projectName,
      },
    }))
  }

  handleProjectDescriptionChange = (projectDescription) => {
    this.setState((state) => ({
      ...state,
      form: {
        ...state.form,
        projectDescription,
      },
    }))
  }

  handleContactsChange = (contacts) => {
    this.setState((state) => ({
      ...state,
      form: {
        ...state.form,
        contacts,
      },
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const form = event.target

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }

    axios
      .post('/', encode({ 'form-name': form.getAttribute('name'), ...this.state.form }), config)
      .then((res) => {
        this.setState({ isSended: true })
      })
      .catch((error) => console.log(error))
  }

  render() {
    const { isSended } = this.state
    const { locale, intl } = this.props
    return (
      <>
        <PageHelmet title={intl.formatMessage({ id: 'home.becomeHero' })} locale={locale} defer={false} />
        <Header full={true} locale={locale} />
        {isSended && <Thanks successMessageId="home.applicationSuccess" />}
        {!isSended && (
          <BecomeHeroPage
            locale={locale}
            onSubmit={this.handleSubmit}
            onNameChange={this.handleNameChange}
            onProjectNameChange={this.handleProjectNameChange}
            onProjectDescriptionChange={this.handleProjectDescriptionChange}
            onContactsChange={this.handleContactsChange}
          />
        )}
        <Footer locale={locale} />
      </>
    )
  }
}

const customProps = {
  localeKey: 'becomeHero',
}

export default withLayout(customProps)(injectIntl(BecomeHero))
